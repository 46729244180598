<template>
  <v-card class="p-0 m-0 my-3 mx-2" flat>
    <v-container class="m-0 p-0">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="m-0 p-0 text-right" v-if="moveLeft">
          <v-btn class="me-1" color="primary" x-small icon @click="$emit('leftClick',item)">
            <i v-i:duo#arrow-fat-lines-left#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
          </v-btn>
        </v-col>
        <v-col cols="" class="m-0 p-0">
          <v-card class="m-0 p-0" @click="$emit('addConteudo(item)')" :color="hexTints(area.cor,0.92)">
            <v-container class="m-0 p-0">
              <v-row fluid class="m-0 p-0" :style="'background-color:'+hexTints(area.cor,tintValue)">
                <v-col cols="" class="m-0 p-0">
                  <v-tooltip bottom color="white" :key="refresh" open-delay="1000">
                    <template v-slot:activator="{ on, attrs }" class="">
                      <p class="m-0 p-0 px-1" v-bind="attrs" v-on="on">
                        <span class="fs-8pt fw-800">ID:</span>
                        <span class="ms-1 fs-8pt fw-600 f-lato">{{item.id}}</span>
                      </p>
                    </template>
                    <v-card class="p-0" v-if="ucs != null">
                      <p class="m-0 p-1 mb-2 line-height-1" :style="'background-color:'+hexTints(area.cor,0.87)">
                        <span class="ms-1 fs-8pt fw-400">Conteúdo</span>
                        <span class="ms-1 fs-8pt fw-800">ID:</span>
                        <span class="ms-1 fs-8pt fw-600 f-lato">{{item.id}}</span>
                        <span class="ms-1 fs-8pt fw-400">indicado em:</span>
                      </p>
                      <div class="px-1">
                        <uclabel :ucID="item.id" v-for="(item,key,index) in ucs" :key="key+refresh"/>
                      </div>
                    </v-card>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="m-0 p-0" :style="'background-color:'+hexTints(area.cor,0.96)">
              <v-row class="m-0 p-0" align="center">
                <v-col class="m-0 p-0">
                  <v-card class="m-0 p-1 px-1 f-raleway fs-10pt line-height-3" flat :color="hexTints(area.cor,0.98)">
                    {{item.texto}}
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="auto" class="m-0 p-0 text-right" v-if="moveRight">
          <v-btn class="ms-1" color="primary" x-small icon @click="$emit('rightClick',item)">
            <i v-i:duo#arrow-fat-lines-right#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>


  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "conteudoitem",
  components: {
    'uclabel': () => import('./uc-label.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
    item: { default: "", type: Object },
    moveLeft: { default: false, type: Boolean },
    moveRight: { default: false, type: Boolean },
  },
  computed: {
    tintValue() {
      if(this.moveLeft) return 0.87;
      if(this.moveRight) return 0.7;
      return 0.87;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      ucs: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      rdb.ref("/curriculo/conteudos/conteudos/"+this.item.id+"/ucs").get().then(function(snap) {
        self.ucs = snap.val();
        self.refresh++;
        console.log("self.ucs",self.ucs);
      });
    }
  }
}
</script>

<style scoped>
</style>
